<template>
  <CCard>
    <CCardHeader>
      <h4 class="subhead-modal">Ideation Manager</h4>
      <div class="subhead-modal-action">
        <CButton color="primary" class="mr-1" @click="OnClickDetail(null)">New</CButton>
      </div>
    </CCardHeader>
    <CCardBody>

      <CRow>
        <CCol md="12">
          <CDataTable
            :items="IdeationManagerData"
            :fields="fields"
            items-per-page-select
            :items-per-page="10"
            :loading="IsLoading"
            hover
            sorter
            pagination
            tableFilter
          >
            <template #ManagerID="{item}">
              <td>
                <CLink class="text-primary" @click="OnClickDetail(item)">{{
                  item.ManagerID
                }}</CLink>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>

      <IdeationManagerModal
        :showModal.sync="showIdeationManagerModal"
        @IdeationManagerModal-updated="showIdeationManagerModal = $event"
        :item.sync="IdeationManagerObj"
        :viewmode="IdeationManagerViewmode"
        @save-success="GetIdeationManagerData()"
      />

    </CCardBody>
  </CCard>
</template>

<script>

import axios from "axios";
import store from "../../store";
import IdeationManagerModal from "@/views/manager/IdeationManagerModal";

const fields = [
  { key: "ManagerID", label: "Manager ID.", _style: "text-align:center" },
  { key: "IndustryDesc", label: "Industry" },
  { key: "ManagerUsername", label: "Username" },
  { key: "ManagerName", label: "Name" },
  { key: "ManagerEmail", label: "Email" },
  { key: "BU", label: "BU" },
];

export default {
  name: "IdeationManagerList",
  components: {
    IdeationManagerModal
  },
  data() {
    return {
      fields,
      IdeationManagerData: [],
      IsLoading: false,
      showIdeationManagerModal: false,
      IdeationManagerObj: null,
      IdeationManagerViewmode: "view",
    };
  },
  mounted() {
    this.GetIdeationManagerData();
  },
  methods: {

    GetIdeationManagerData() {

      axios.get(store.getters.URL + "/api/MasterIdeationManager/getall")
      .then(res => {
        //console.log("res",res);
        this.IdeationManagerData = res.data;
      })
      .catch(err => {
        console.error("err",err);
      });

    },
    OnClickDetail(item){
      this.showIdeationManagerModal = true;
      this.IdeationManagerObj = item;
      this.IdeationManagerViewmode = item === null ? "create" : "edit";
    }

  },
};

</script>
