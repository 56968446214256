<template>
  <div>

    <!-- Modal  start-->
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      title="Modal title 2"
      size="xl"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">Ideation Manager</h6>
        <CButtonClose @click="CloseModal()" id="btnCloseModal" class="text-white" />
      </template>

      <div>

          <CRow>
            <CCol md="6">
              <CInput
                label="ManagerID"
                horizontal
                v-model="DataObj.ManagerID"
                readonly
              />
            </CCol>
            <CCol md="6" class="form-group form-row">
              <div class="col-md-3">
                <label class="">Industry</label>
              </div>
              <div class="col-md-9 pr-0">
                <v-select
                  v-model="DataObj.IndustryID"
                  :value.sync="DataObj.IndustryID"
                  :options="IndustryOptions"
                  placeholder="-- Select --"
                />
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="6">
              <CInput
                label="Username"
                horizontal
                v-model="DataObj.ManagerUsername"
              />
            </CCol>
            <CCol md="6">
              <CInput
                label="Name"
                horizontal
                v-model="DataObj.ManagerName"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol md="6">
              <CInput
                label="E-mail"
                horizontal
                v-model="DataObj.ManagerEmail"
              />
            </CCol>
            <CCol md="6" class="form-group form-row">
              <div class="col-md-3">
                <label class="">BU</label>
              </div>
              <div class="col-md-9 pr-0">
                <v-select
                  v-model="DataObj.BU"
                  :value.sync="DataObj.BU"
                  :options="buOptions"
                  placeholder="-- Select --"
                />
              </div>
            </CCol>
          </CRow>

          

      </div>

      <template #footer>
          <CButton @click="SaveData('Save')" v-if="viewmode !== 'view'" color="primary">
            Save
          </CButton>
          <CButton @click="DeleteData()" v-if="viewmode !== 'create' && viewmode !== 'view'" color="danger">
            Delete
          </CButton>
          <CButton @click="CloseModal()" id="btnCancel" color="secondary">
            Cancel
          </CButton>

      </template>
    </CModal>
    <!-- Modal  end-->

  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import store from "../../store";
import axios from "axios";

var URL_GetAll = store.getters.URL + "/api/MasterIdeationManager/getall";
var URL_GetByID = store.getters.URL + "/api/MasterIdeationManager/getbyid";
var URL_Insert = store.getters.URL + "/api/MasterIdeationManager/insert";
var URL_Update = store.getters.URL + "/api/MasterIdeationManager/update";
var URL_Delete = store.getters.URL + "/api/MasterIdeationManager/delete";
var URL_GetBu = store.getters.URL + "/api/MasterBu/getall";

export default {
  name: "IdeationManagerModal",
  components: {
    vSelect,
  },
  data() {
    return {

      DataObj : this.NewObj(null),
      IndustryOptions: [{ value: null, label: "--Select--" }],
      buOptions: [{ value: "", label: "--Select--" }],
      // userprofile: JSON.parse(localStorage.getItem("userprofile")),
      // userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      userprofile: JSON.parse(localStorage.getItem("userprofile")),
      userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      
    };
  },
  props: {
    showModal: Boolean,
    item: Object,
    viewmode: {
      type: String,
      default: "view",
      required: false
    }
  },
  mounted() {

    setTimeout(
      function() {
        this.LoadIndustry();
        this.GetBu();
      }.bind(this),
      100
    );

  },
  methods: {

    GetBu(){
      axios.get(URL_GetBu).then((res) => {
        let options = [{ value: "", label: "-- Select --" }];
        //console.log("res", res);
        if (res !== null) {
          res.data.forEach(function (data) {
            options.push({
              value: data.BUID,
              label: data.BUDesc,
            });
          });
        }
        this.buOptions = options;
      });
    },
    LoadById(Id) {

      axios
      .get(URL_GetByID, {
        params: {
          Id: Id
        }
      })
      .then(response => {
        if (response !== null) {
          //console.log("response",response);
          this.DataObj = this.NewObj(response.data);
        }
      });
    },
    NewObj(Obj) {

      var NewObj = {
          
          ManagerID : Obj === null ? null : Obj.ManagerID,
          IndustryID : this.ConvertDataApiToDropdown(this.IndustryOptions, Obj, false),
          //IndustryID : Obj === null ? null : this.IndustryOptions[this.IndustryOptions.findIndex(x => x.value === (Obj.IndustryID === null ? Obj.IndustryID : Obj.IndustryID.value)) ],
          IndustryDesc : Obj === null ? null : Obj.IndustryDesc,
          ManagerUsername : Obj === null ? null : Obj.ManagerUsername,
          ManagerName : Obj === null ? null : Obj.ManagerName,
          ManagerEmail : Obj === null ? null : Obj.ManagerEmail,
          BU: this.ConvertDataApiToDropdown(this.buOptions, Obj, true),
          BUID: Obj === null ? 0 : 0,
          //BU : Obj === null ? null : this.buOptions[this.buOptions.findIndex(x => x.label === '')],

      }

      return NewObj;
    },
    ConvertDataApiToDropdown(dropdownOption, dataApi, isBu)
    {
      let keyFilter = null;
      let index = null;

      if(dataApi != null && isBu){
        if(dataApi.BU == null)
        {
          return null;
        }
        if(typeof dataApi.BU === 'string'){
          keyFilter = dataApi.BU;
        }
        else{
          keyFilter = dataApi.BU.label;
        }
      }
      else if(dataApi != null && !isBu){
        if(dataApi.IndustryID == null)
        {
          return 0;
        }
        if(typeof dataApi.IndustryID === 'string'){
          keyFilter = dataApi.IndustryID;
        }
        else{
          keyFilter = dataApi.IndustryID.value;
        }
      }
      else{
        return null;
      }

      if(isBu){
        index = dropdownOption.findIndex(e => e.label === keyFilter);
      }
      else{
        index = dropdownOption.findIndex(e => e.value === keyFilter);
      }

      return dropdownOption[index];
    },
    ConvertDropdown(saveObj){
 
        saveObj = this.NewObj(saveObj);

        if (saveObj.IndustryID != null && saveObj.IndustryID !== undefined) {
          if(saveObj.IndustryID.value !== undefined){
            saveObj.IndustryID = saveObj.IndustryID.value;
          }
        }

         if (saveObj.BU != null && saveObj.BU !== undefined) {
          if(saveObj.BU.value !== undefined){
            let temp = saveObj.BU;
            saveObj.BU = temp.label;
            saveObj.BUID = temp.value;
          }
        }

        return saveObj;
    },
    CloseModal() {
      //this.viewmode === "view" && this.SetViewMode(false);
      this.isshow = false;
      //console.log("isshow",this.isshow);
      this.DataObj = this.NewObj(null);
    },
    SaveData(action) {
      let saveObj = this.NewObj(this.DataObj);
      saveObj = this.ConvertDropdown(saveObj);

      // console.log("viewmode",this.viewmode);
      // console.log("saveObj",saveObj);

      if (this.viewmode === "create") {
        this.InsertData(saveObj, action);
      }
      else if (this.viewmode === "edit") {
        this.UpdateData(saveObj, action);
      }

    },
    InsertData(saveObj, action) {

      axios
      .put(URL_Insert, saveObj)
      .then(response => {

        //console.log("response", response);
        if (response.status === 200) {
          this.$_toast_Noti("success", "success", "");
          this.CloseModal();
          this.$emit("save-success");
        }

      })
      .catch(error => {
        //console.log("error",error);
        this.$_toast_Noti("error", "error", error);
      })

    },
    UpdateData: function(saveObj, action) {
      axios
      .post(URL_Update, saveObj)
      .then(response => {
        
        //console.log("response", response);
        if (response.status === 200) {
          this.$_toast_Noti("success", "success", "");
          this.CloseModal();
          this.$emit("save-success");
        }
        
      })
      .catch(error => {
        //console.log("error", error);
        this.$_toast_Noti("error", "error", error);        
      })
      
    },
    DeleteData: function() {
      if (confirm("Do you want to delete?")) {
 
        let saveObj = this.NewObj(this.DataObj);
        saveObj = this.ConvertDropdown(saveObj);
        
        axios
        .post(URL_Delete, saveObj)
        .then(response => {
          //console.log("response", response);
          if (response.status === 200) {
            this.$_toast_Noti("success", "success", "");
            this.CloseModal();
            this.$emit("save-success");
          }
        })
        .catch(error => {
          //console.log("error", error);
          this.$_toast_Noti("error", "error", error);
        })

      }
    },

    LoadIndustry() {
      axios.get(store.getters.URL + "/api/MasterIndustry/getall").then(res => {
        let Options = [{ value: null, label: "-- Select --" }];
        if (res !== null) {
          res.data.forEach(function(data) {
            Options.push({
              value: data.IndustryID,
              label: data.IndustryDesc
            });
          });
        }
        this.IndustryOptions = Options;
      });
    },
    
  },
  computed: {
    isshow: {
      get() {
        return this.showModal;
      },
      set(v) {
        this.$emit("IdeationManagerModal-updated", v);
      }
    },
    InsightObject: {
      get() {
        return this.item;
      },
      set(v) {
        this.$emit("IdeationManagerObject-updated", v);
      }
    },
    userRole: function () {
      return this.userprofile.Role;
    }
  },
  watch: {
    item: function(newVal, oldVal) {

      //console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if (newVal !== null) {

        setTimeout(
          function() {
            //console.log('LoadById');
            this.LoadById(newVal.ManagerID);       
            //this.viewmode === "view" && this.SetViewMode(true);
          }.bind(this),
          1000
        );

      }
      //console.log("this.insightProcessID",this.insightProcessID);
    }
  }
};
</script>

